import React from 'react'

import { useForm } from "react-hook-form";

import '../styles/login.less'

export default function NextCloudConnect ({ connectWithNextCloud, nextCloudUrl = "", projectName = 'mindmap', location, ...rest}) {
  const { handleSubmit, register } = useForm();

  const onSubmit = data => {
    connectWithNextCloud(data.nextCloudUrl, data.projectName)
  };

  return (
    <div className="node_view__dialog node_view__dialog--open">
      <div className="box">
        <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="login_form__section">
            <label htmlFor="nextCloudUrl">Server Url:</label>
            <input className="field" type="url" defaultValue={nextCloudUrl} name="nextCloudUrl" ref={register} />
          </div>
          <div className="login_form__section">
            <label htmlFor="projectName">PorjectName:</label>
            <input className="field" type="text" defaultValue={projectName} name="projectName" ref={register} />
          </div>
          <div className="login_form__section">
            <input className="button" type="submit" value="Connect"/>
          </div>
        </form>
      </div>
    </div>
  )
}
