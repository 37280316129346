import { createSelector } from 'reselect'

export function selectIndex (state) {
  return state.data.index
}

export function selectLoadingCount(state) {
  return state.graph.loading
}

export const selectIsLoading = createSelector(
  [selectLoadingCount],
  (loadingCount ) => {
    if(loadingCount < 0){
      console.error("SOMETHING WENT WRONG WITH LOADINGCOUNT")
    }
    return loadingCount > 0;
  }
)
