import { connect } from 'react-redux'

import View, {LogoutView} from '../components/user.login.js'

import {
  selectIsLoggedIn,
  selectDavUrl
} from '../selectors/user.selectors.js'

import {
  loginUser,
  logoutUser,
} from '../actions/user.actions.js'

const mapStateToProps = (state, props) => {
  return {
    isLoggedIn: selectIsLoggedIn(state, props),
    davUrl: selectDavUrl(state, props),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (username, password, davUrl, projectName, goBackTo) => {
     dispatch(loginUser(username, password, davUrl, projectName, goBackTo))
    }
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container

export const Logout = connect(
  () => {},
  (dispatch) => {
    return {
      logoutUser: () => dispatch(logoutUser())
    }
  }
)(LogoutView)
