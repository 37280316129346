import { createSelector } from 'reselect'

export function selectNodes (state) {
  return state.graph.nodesById 
}

export function selectId (state, props) {
  if(props.match && props.match.params.id){
    return props.match.params.id
  } else {
    return props.id
  }
}

export function selectIds (state, props){
  return props.ids
}
export function selectNodesFilter (state, props) {
  if(props){
    if(props.filter === "TO") {
      return props
    }
  }
  return {filter:"IDENT"}
}

export const selectNodeByIds = createSelector(
  [selectNodes, selectIds],
  (nodes, ids) => ids.map(id => nodes[id] ? nodes[id]: {isNew:true, id, name: id, content: "", from: [], to:[]})
)


export const selectNodesAsArray = createSelector(
  [selectNodes, selectNodesFilter],
  (nodes, myFilter) => {
    if(myFilter.filter === "IDENT"){
      return Object.values(nodes)
    } else if (myFilter.filter === "TO") {

      return Object.values(nodes).filter((n) => n.to.includes(myFilter.nodeID))
    }
  }
)

export const selectCurrentNode = createSelector(
  [selectNodes, selectId],
  (nodes, id) => {
    if(id && nodes[id]) {
      return nodes[id]
    } else if(id) {
      return {
        id: id,
        content: '',
        from: [],
        to: [],
        new: true
      }
    } else {
      return Object.values(nodes)[0]
    }   
  }
)

