import getToLinks from './findAllInternalLinks.js'

export default function transformNode({id, content}) {
  return {
    id,
    content,
    to: getToLinks(content),
    from: []
  }
}
