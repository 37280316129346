import React from 'react'
import idToName from '../utils/idToName.js'

export default function CurrentNodeButton ({ 
  node, 
  openDialog, 
  closeDialog, 
  dialogOpen 
}) {
  function toggleDialog(){
    if(dialogOpen) {
      closeDialog()
    } else {
      openDialog()
    }
  }

  if(!node){
    return ''
  }

  return (
    <button 
      className="button button--head"
      onClick={toggleDialog}
    >{idToName(node.id)}</button>
  )
}
