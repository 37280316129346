import { createFailAction } from './_base.actions'
import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  SAVE_USER_START,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAIL,
  LOAD_USER_START,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL
} from '../constants/user.constants.js'

/**
 * @param {string} username
 * @param {string} password
 * @param {string} davUrl
 */
export function loginUser(username, password, davUrl = false, projectName = 'mindmap', goBackTo = '/'  ){

  return {
    type: LOGIN_USER_START,
    username,
    password,
    goBackTo,
    projectName,
    davUrl
  }
}
/**
 * @param {string} username
 * @param {string} password
 * @param {string} davUrl
 */
export function loginUserSuccess(username, davUrl = false, projectName = 'mindmap', goBackTo = '/' ){

  return {
    type: LOGIN_USER_SUCCESS,
    username,
    davUrl,
    projectName,
    goBackTo,
  }
}
/**
 * @param {Object} error
 */
export const loginUserFail = createFailAction(LOGIN_USER_FAIL)

/**
 * logout user action
 */
export function logoutUser () {
  return {
    type: LOGOUT_USER
  }
}

/*
 * save user data
 * @param {Object} user
 */ 
export function saveUser(user){
  return {
    type: SAVE_USER_START,
    user
  }
}

/*
 * save user data successfull
 * @param {Object} user
 */ 
export function saveUserSuccess(user){
  return {
    type: SAVE_USER_SUCCESS,
    user
  }
}

/*
 * save user data failed
 * @param {string} error
 */ 
export const saveUserFail = createFailAction(SAVE_USER_FAIL)


/*
 * clear saved user
 */ 
export function clearUser(){
  return {
    type: SAVE_USER_START,
    user: null
  }
}
/*
 * load user data
 * @param {Object} user
 */ 
export function loadUser(){
  return {
    type: LOAD_USER_START
  }
}

/*
 * load user data successfull
 * @param {Object} user
 */ 
export function loadUserSuccess(user){
  return {
    type: LOAD_USER_SUCCESS,
    user
  }
}

/*
 * load user data failed
 * @param {string} error
 */ 
export const loadUserFail = createFailAction(LOAD_USER_FAIL)
