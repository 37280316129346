import {
  DATA_TYPE_GRAPH,
  DATA_TYPE_NODE,
  SAVE_NODE_START,
  SAVE_NODE_SUCCESS,
  SAVE_NODE_FAIL,
  UPDATE_NODE_TO,
  UPDATE_NODE_FROM,
  SAVE_NODE_NEW
} from '../constants/graph.constants.js'

import {
  loadData,
  saveData,
} from './data.actions.js'

/**
 */
export function loadGraph(){
  return loadData(DATA_TYPE_GRAPH, "")
}
/**
 * @param {string} nodeID
 */
export function loadNode(nodeID){
  return loadData(DATA_TYPE_NODE, nodeID)
}

/**
 * @param {Node} node
 */
export function saveNode(node){
  return {
    type: SAVE_NODE_START,
    node
  }
}
/**
 * @param {Node} node
 */
export function saveNewNode(node){
  return {
    type: SAVE_NODE_NEW,
    node
  }
}
/**
 * @param {Node} node
 */
export function saveNodeSuccess(node){
  return {
    type: SAVE_NODE_SUCCESS,
    node
  }
}
/**
 * @param {Node} node
 * @param {Object} error
 */
export function saveNodeFail(node, error){
  return {
    type: SAVE_NODE_FAIL,
    node
  }
}
/**
 * @param {string} nodeID - ID of node to update
 * @param {string[]} from - array of node IDs
 */
export function updateNodeFrom(nodeID, from){
  return {
    type: UPDATE_NODE_FROM,
    nodeID,
    from,
  }
}
/**
 * @param {string} nodeID- ID of node to update
 * @param {string[]} to - array of node IDs
 */
export function updateNodeTo(nodeID, to){
  return {
    type: UPDATE_NODE_TO,
    nodeID,
    to,
  }
}

/**
 * @param {Node} node
 */
export function saveNodeToServer(node){
  return saveData(DATA_TYPE_NODE, node, `/mindmap/${node.id}`)
}
