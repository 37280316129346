import { combineEpics, createEpicMiddleware } from 'redux-observable'
import dataEpics from './data.epics.js'
import graphEpics from './graph.epics.js'
import userEpics from './user.epics.js'
import nextcloudEpics from './nextcloud.epics.js'
import messagesEpics from './messages.epics.js'

import Fuse from 'fuse.js'

import {
  getAllNodes,
  getNodeContent,
  saveNodeContent,
  initClient,
  checkNodeExists,
  checkFolderExists,
  clearClient
} from '../utils/webdav.js'

import {
  nextCloudLogin,
  pollNextCloud
} from '../utils/nextCloud.js'

import {
  storeUserData,
  getUserData
} from '../utils/db/local.js'

const rootEpic = combineEpics(
  dataEpics,
  graphEpics,
  userEpics,
  nextcloudEpics,
  messagesEpics
)

export const configureEpicMiddleware = () => createEpicMiddleware({
  dependencies: {
    getAllNodes,
    getNodeContent,
    saveNodeContent,
    checkNodeExists,
    checkFolderExists,
    initClient,
    clearClient,
    createIndex: Fuse.createIndex,
    nextCloudLogin,
    pollNextCloud,
    storeUserData,
    getUserData
  }
})

export default rootEpic
