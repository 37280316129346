import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './createStore.js'

import App from './components/app.js';

import * as serviceWorker from './serviceWorker';

//import { initClient } from './utils/webdav.js'
//
import { loadUser } from './actions/user.actions.js'

const { store } = configureStore()

store.dispatch(loadUser())


//initClient()

ReactDOM.render(
  /* <React.StrictMode>*/
    <Provider store={store}>
      <ConnectedRouter history={history} basename={process.env.PUBLIC_URL}>
        <App />
      </ConnectedRouter>
    </Provider>
  /* </React.StrictMode>*/,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
