import {
  ADD_MESSAGE,
  HIDE_MESSAGE
} from '../constants/messages.constatns.js'

/*
 * @param {MessageType} messageType
 * @param {String} text
 * @param {String} uuid
 * @param {number} timestamp - for injecting a timestamp if needed
 * @return {Object}
 */
export function addMessage (messageType, text, timestamp = Date.now()) {
  return {
    type: ADD_MESSAGE,
    messageType,
    text,
    timestamp,
  }
}

/*
 * @param {String} id
 * @return {Object}
 */
export function hideMessage (id) {
  return {
    type: HIDE_MESSAGE,
    id
  }
}
