import { mergeMap, catchError, filter, map } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import transformNodeArrayToObject from '../utils/transformNodeArrayToObject.js'
import transformNode from '../utils/transformNode.js'

import {
  selectNodesAsArray,
} from '../selectors/nodes.selectors.js'

import {
  selectProjectName,
} from '../selectors/user.selectors.js'

import {
  LOAD_DATA_START,
  SAVE_DATA_START,
  LOAD_DATA_SUCCESS,
  SAVE_DATA_SUCCESS,
  UPDATE_SEARCH_INDEX,
  FUSE_OPTIONS
} from '../constants/data.constants.js'

import {
  DATA_TYPE_GRAPH,
  DATA_TYPE_NODE
} from '../constants/graph.constants.js'

import {
  loadDataSuccess,
  loadDataFail,
  saveDataSuccess,
  saveDataFail,
  updateSearchIndex,
  saveSearchIndex,
} from '../actions/data.actions.js'

import {
  logoutUser
} from '../actions/user.actions.js'


export function loadGraphEpic (action$, state$, { getAllNodes }) {
  return action$.pipe(
    ofType(LOAD_DATA_START),
    filter(({datatype}) => datatype === DATA_TYPE_GRAPH),
    mergeMap(async action => {
      console.log(state$.value)
      const projectFolder = `/${selectProjectName(state$.value)}`
      console.log("projectFolder: ", projectFolder)
      const nodes = await getAllNodes(projectFolder)


      return loadDataSuccess(
        action.datatype,
        {
          nodes: transformNodeArrayToObject(nodes)
        },
        Date.now()
      )
    }),
    catchError((e, action) => {
      console.error('loading error')
      console.error(e, action)
      return [
        loadDataFail(DATA_TYPE_GRAPH, '', e),
        logoutUser()
      ]
    })
  )
}
export function saveSearchIndexEpic(action$, state$, { createIndex } ) {
  return action$.pipe(
    ofType(UPDATE_SEARCH_INDEX),
    map(action => {

      const nodes = selectNodesAsArray(state$.value)
      console.log("BUILD INDEX: nodes", nodes)
      console.log("BUILD INDEX: options", FUSE_OPTIONS)
      const index = createIndex(FUSE_OPTIONS.keys, nodes)
      console.log("BUILD INDEX: index", index)


      return saveSearchIndex(index)
    })
  )
}

const searchIndexEpics = combineEpics(
  saveSearchIndexEpic,
  (action$, state$, { createIndex } )  => action$.pipe(
    ofType(LOAD_DATA_SUCCESS),
    map(action => updateSearchIndex())
  ),
  (action$, state$, { createIndex } )  => action$.pipe(
    ofType(SAVE_DATA_SUCCESS),
    map(action => updateSearchIndex())
  ),

)

export function loadNodeEpic (action$, state$, { checkNodeExists, getNodeContent }) {
  return action$.pipe(
    ofType(LOAD_DATA_START),
    filter(({datatype}) => datatype === DATA_TYPE_NODE),
    mergeMap(async action => {
      const projectFolder = `/${selectProjectName(state$.value)}`

      const exists = await checkNodeExists(action.src, projectFolder)

      if(!exists){
        return loadDataSuccess(
          action.datatype,
          transformNode({id: action.src, content: ''}),
          Date.now()
        )
      }

      const result = await getNodeContent(action.src, projectFolder)


      return loadDataSuccess(
        action.datatype,
        transformNode(result),
        Date.now()
      )
    }),
    catchError((e, action) => {
      console.error('loading error')
      console.error(e, action)
      return [
        loadDataFail(DATA_TYPE_NODE, '', e),
        logoutUser()
      ]
    })
  )
}
export function saveNodeEpic (action$, state$, { saveNodeContent }) {
  return action$.pipe(
    ofType(SAVE_DATA_START),
    filter(({datatype}) => datatype === DATA_TYPE_NODE),
    mergeMap(async action => {
      const projectFolder = `/${selectProjectName(state$.value)}`
      await saveNodeContent(action.data.id, action.data.content, projectFolder)

      return saveDataSuccess(
        action.datatype,
        {
          nodes: transformNodeArrayToObject([action.data])
        },
        Date.now()
      )
    }),
    catchError((e, action) => {
      console.error('saving error')
      console.error(e, action)
      return [
        saveDataFail(action.datatype, action.src, e),
        logoutUser()
      ]
    })
  )
}


export default combineEpics(
  loadGraphEpic,
  loadNodeEpic,
  searchIndexEpics,
  saveNodeEpic
)
