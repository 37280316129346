import React from 'react';
import { Route, Switch } from 'react-router'
import ProtectedRoute from '../containers/routes.container.js'
import NodeView from '../containers/node.container.js'
import GraphIndex from '../containers/graph.index.container.js'
import Login, {Logout} from '../containers/user.container.js'
import NextCloud from '../containers/nextcloud.container.js'

import Messages from '../containers/messages.container.js'

import Toolbar from './toolbar.js'

import '../styles/main.less'

export default function App() {
  return (
    <div className="app">
      <Toolbar />
      <main>
        <Switch>
          <Route exact path='/login' render={(p) => <Login {...p}/>}/>
          <Route exact path='/logout' render={(p) => <Logout {...p}/>}/>
          <Route exact path='/nextcloud' render={(p) => <NextCloud {...p}/>}/>
          <ProtectedRoute exact path='/'          render={(props) => <GraphIndex {...props}/>} />
          <ProtectedRoute exact path='/graph'     render={(props) => <GraphIndex {...props}/>} />
          <ProtectedRoute exact path="/graph/:id" render={(props) => <NodeView {...props} /> } />
          <Route render={() => (<p>404</p>)} />
        </Switch>
      </main>
      <Messages />
    </div>
  );
}
