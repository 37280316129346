import { createSelector } from 'reselect'
import Fuse from 'fuse.js'

import {selectIndex} from './data.selectors.js'
import {selectNodesAsArray} from './nodes.selectors.js'

import {FUSE_OPTIONS } from '../constants/data.constants.js'

export function selectDialogs(state) {
  return state.ui.dialog
}

export function selectDialogIdFromProps (state, props) {
  return props.dialogID
}

export function selectNodeMode(state){
  return state.ui.nodeMode
}

export function selectSearchString(state){
  return state.ui.search
}

export const selectDialogOpen = createSelector(
  [selectDialogs, selectDialogIdFromProps],
  (dialogs, dialogID) => dialogs[dialogID]
)

export const selectNodeFuzzy = createSelector(
  [selectNodesAsArray, selectIndex, selectSearchString],
  (nodeNames, index, searchString) => {
    console.log("selectNodeFuzzy nodes",nodeNames)
    console.log("selectNodeFuzzy index",index)
    console.log("selectNodeFuzzy searhc",searchString)
    if(searchString === ''){
      console.log("no searchstring")
      return []
    }
    const fuse = new Fuse(nodeNames, FUSE_OPTIONS, index)
    const res = fuse.search(searchString)
    console.log("selectNodeFuzzy res",res)
    return res
  }
)
