import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SWITCH_NODE_MODE,
  DIALOG_ID_NODE,
  NODE_MODE_TEXT,
  SET_SEARCH_STRING
} from '../constants/ui.constants.js'

export const initialState = {
  loading: false,
  dialog: {
    [DIALOG_ID_NODE]: false
  },
  nodeMode: NODE_MODE_TEXT,
  search: ''
}

export default function graph (state = initialState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          [action.dialog]: true
        }
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          [action.dialog]: false
        }
      }
    case SWITCH_NODE_MODE:
      return {
        ...state,
        nodeMode: action.mode
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        search: action.search
      }
    default:
    return state
  }
}
