import {
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  LOAD_DATA_FAIL,
  SAVE_DATA_START,
  SAVE_DATA_SUCCESS,
  SAVE_DATA_FAIL,
  SAVE_SEARCH_INDEX,
  UPDATE_SEARCH_INDEX
} from '../constants/data.constants.js'

/**
 * @param {sting} datatype
 * @param {string} src
 */
export function loadData(datatype, src){
  return {
    type: LOAD_DATA_START,
    datatype,
    src
  }
}

/**
 * @param {sting} datatype
 * @param {Object} data
 */
export function loadDataSuccess(datatype, data, timestamp){
  return {
    type: LOAD_DATA_SUCCESS,
    datatype,
    data,
    timestamp
  }
}

/**
 * @param {sting} datatype
 * @param {string} src
 * @param {Object} error
 */
export function loadDataFail(error, datatype){
  return {
    type: LOAD_DATA_FAIL,
    datatype,
    error
  }
}
/**
 * @param {sting} datatype
 * @param {string} src
 */
export function saveData(datatype, data, src){
  return {
    type: SAVE_DATA_START,
    datatype,
    data,
    src
  }
}

/**
 * @param {sting} datatype
 * @param {Object} data
 */
export function saveDataSuccess(datatype, data, timestamp){
  return {
    type: SAVE_DATA_SUCCESS,
    datatype,
    data,
    timestamp
  }
}

/**
 * @param {sting} datatype
 * @param {string} src
 * @param {Object} error
 */
export function saveDataFail(error, datatype){
  return {
    type: SAVE_DATA_FAIL,
    datatype,
    error
  }
}

/**
 * @param {?} index- 
 */
export function updateSearchIndex(){
  return {
    type: UPDATE_SEARCH_INDEX,
  }
}
/**
 * @param {?} index- 
 */
export function saveSearchIndex(index){
  return {
    type: SAVE_SEARCH_INDEX,
    index
  }
}
