import { connect } from 'react-redux'

import View from '../components/messages.js'

import { selectNewestMessages } from '../selectors/messages.selectors.js'

import { hideMessage } from '../actions/messages.actions.js'

const mapStateToProps = state => {
  return {
    messages: selectNewestMessages(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onMessageRead: (id) => dispatch(hideMessage(id))
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
