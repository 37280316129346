import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import graph, {initialState as graphState} from './graph.reducers.js'
import ui, {initialState as uiState} from './ui.reducers.js'
import user, {initialState as userState} from './user.reducers.js'
import messages, {initialState as messagesState} from './messages.reducers.js'
import data, {initialState as dataState} from './data.reducers.js'

export const initialState = {
  graph: graphState,
  ui: uiState,
  data: dataState,
  user: userState,
  messages: messagesState
}

export default function createRootReducer (history) {
  return combineReducers({
    router: connectRouter(history),
    graph,
    ui,
    data,
    user,
    messages,
  })
}
