import {
  USER_ANNONYMOUS
} from '../constants/user.constants.js'

export function selectUser(state) {
  return state.user.user
}
export function selectProjectName(state) {
  return state.user.projectName
}
export function selectDavUrl(state) {
  return state.user.davUrl
}

export function selectIsLoggedIn (state) {
  return state.user.user !== USER_ANNONYMOUS
}
