import React from 'react'
import { Link } from 'react-router-dom'

import { useForm } from "react-hook-form";

import '../styles/login.less'

export default function UserLogin ({ loginUser, davUrl = "", location, projectName="mindmap", ...rest}) {
  const goBackTo = location.from ? location.from : '/'

  const { handleSubmit, register } = useForm();

  const onSubmit = data => {
    console.log("LOGIN:", data)
    if(data.username === "" && process.env && process.env.NODE_ENV === 'development'){
      console.log("ENV: development: login:", 
        process.env.REACT_APP_WEBDAV_USERNAME,
        process.env.REACT_APP_WEBDAV_PASSOWRD,
        process.env.REACT_APP_WEBDAV_URL,
        goBackTo
      )
      loginUser(
        process.env.REACT_APP_WEBDAV_USERNAME,
        process.env.REACT_APP_WEBDAV_PASSOWRD,
        process.env.REACT_APP_WEBDAV_URL,
        goBackTo
      )
    } else {
      loginUser(data.username, data.password, data.davUrl, data.projectName, goBackTo)
    }
  };

  return (
    <div className="node_view__dialog node_view__dialog--open">
      <div className="box">
        <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="login_form__section">
            <label htmlFor="davUrl">Project Name:</label>
            <input className="field" type="text" defaultValue={projectName} name="projectName" ref={register} />
          </div>
          <div className="login_form__section">
            <label htmlFor="davUrl">DAV Url:</label>
            <input className="field" type="url" defaultValue={davUrl} name="davUrl" ref={register} />
          </div>
          <div className="login_form__section">
            <label htmlFor="username">User:</label>
            <input className="field" type="text" name="username" ref={register} />
          </div>
          <div className="login_form__section">
            <label htmlFor="password">Password:</label>
            <input className="field" type="password" name="password" ref={register} />
          </div>
          <div className="login_form__section">
            <input className="button" type="submit" value="Login"/>
            <Link 
              className="button"
              to={`/nextcloud`}
            >nextcloud</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export function LogoutView ({ logoutUser }) {

  const onSubmit = () => {
    logoutUser()
  };

  return (
    <div className="node_view__dialog node_view__dialog--open">
      <div className="box">
        <p>Do you realy want to logout?</p>
        <form className="login_form" onSubmit={onSubmit}>
          <div className="login_form__section">
            <Link className="button button--blue" to="/">Cancel</Link>
            <input className="button button--red" type="submit" value="Logout"/>
          </div>
        </form>
      </div>
    </div>
  )
}
