import { connect } from 'react-redux'

import View from '../components/searchBar.js'

import { 
  selectSearchString,
  selectNodeFuzzy
} from '../selectors/ui.selectors.js'

import {
  setSearchString,
} from '../actions/ui.actions.js'

const mapStateToProps = (state, props) => {
  return {
    searchString: selectSearchString(state, props),
    searchResults: selectNodeFuzzy(state, props),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSearch: (searchString) => dispatch(setSearchString(searchString))
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
