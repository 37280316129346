import React from 'react' 
import { Route, Switch } from 'react-router'
import {Link} from 'react-router-dom'
import SearchBar from '../containers/searchBar.container.js'
import { CurrentNodeButton }from '../containers/node.container.js'

import '../styles/toolbar.less'

export default function Toolbar (  ) {
  return (
    <header className="head">
      <div>
      <Link className="button button--head" to="/">HOME</Link>
      <Switch>
        <Route exact path='/graph/:id' component={CurrentNodeButton}/>
      </Switch>
      </div>
      <SearchBar />
      <div className="head_spacer"></div>

      <Link className="button button--head button--red" to="/logout">Logout</Link>
    </header>
  )
}
