import { switchMap, catchError, map, takeUntil } from 'rxjs/operators'
import { fromEvent ,} from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import {
  NEXTCLOUD_CONNECT_START,
  NEXTCLOUD_CONNECT_POLL,
  NEXTCLOUD_CONNECT_SUCCESS
} from '../constants/nextcloud.constants.js'

import {
  connectWithNextCloudFail,
  connectWithNextCloudSuccess
} from '../actions/nextcloud.actions.js'

import {
  loginUser,
  saveUser,
} from '../actions/user.actions.js'

export function connectWithNextCloudEpic(action$, state$, { nextCloudLogin }) {
  return action$.pipe(
    ofType(NEXTCLOUD_CONNECT_START),
    switchMap( action => {
      const appUrl = `${action.nextCloudUrl}/index.php/apps/webapppassword`
      const wnd = window.open(appUrl + "?target-origin=" + encodeURIComponent(window.location.href), "Nextcloud Login", "width=400,height=400,menubar=no,scrollbars=no,status=no,titlebar=no,toolbar=no");
      return fromEvent(window, 'message').pipe(
        map(e => {
          console.log("EVENT:", e)
          return e
        }),
        map(event => ({
          type: 'NEXTCLOUD_CONNECT_POLL',
          wnd,
          event,
          userConfig: action.userConfig
        })),
        takeUntil(action$.ofType('NEXTCLOUD_CONNECT_SUCCESS'))
      )

    }),
    catchError((e) => {
      console.error(e)
      return [
        connectWithNextCloudFail(e),
      ]
    })
  )
} 

export function nextCloudConnectPolling(action$, state$, { pollNextCloud }) {
  return action$.pipe(
    ofType(NEXTCLOUD_CONNECT_POLL),
    switchMap( async action => {
      console.log("ACTION:", action)
      if(action.event){
        const data = action.event.data

        if(data.type === "webapppassword"){
          if(action.wnd && action.wnd !== null){
            action.wnd.close()
          }
          console.log(data)
          console.log(action)

          return connectWithNextCloudSuccess(data.webdavUrl, data.loginName, data.token, action.userConfig)
        }
      }
      return connectWithNextCloudFail("somethings wrong")
    }),
    catchError((e) => {
      console.log(e)
      return [
        connectWithNextCloudFail(e)
      ]
    })
  )
}

export function nextCloudConnectSuccess(action$, state$) {
  return action$.pipe(
    ofType(NEXTCLOUD_CONNECT_SUCCESS),
    switchMap(action => {
      const user = {
        name: action.loginName,
        password: action.appPassword,
        davUrl: action.serverUrl,
        nextCloud: true,
        nextCloudServer: action.serverUrl,
        projectName: action.userConfig
      }
      console.log(user.name)
      console.log(action)

      return [
        loginUser(user.name, user.password, user.davUrl, action.userConfig),
        saveUser(user)
      ]
    })
  )
}

export default combineEpics(
  connectWithNextCloudEpic,
  nextCloudConnectPolling,
  nextCloudConnectSuccess
)
