import { connect } from 'react-redux'

import View from '../components/routes.custom.js'

import { 
  selectIsLoggedIn
} from '../selectors/user.selectors.js'

const mapStateToProps = (state, props) => {
  return {
    loggedIn: selectIsLoggedIn(state, props),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
