import localForage from 'localforage';

export function configDatabase(){
  localForage.config({
      driver      : localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
      name        : 'brainstorm',
      version     : 1.0,
      size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName   : 'brainstorm_datastore', // Should be alphanumeric, with underscores.
      description : 'brainstorm datastore'
  });
}

export async function storeUserData(userData){
  await localForage.setItem('user', userData)
}

export async function getUserData(){
  const user = await localForage.getItem('user')

  if(user === null){
    throw new Error('User not Found')
  } else {
    return user
  }
}
