export const color_dark = '#313440'
export const color_green = '#5fff87'
export const color_red = '#ff6059'
export const color_blue = '#5fafff'
export const color_yellow = '#ffff87'
export const color_pink= '#ff79c6'
export const color_orange = '#ff875f'
export const color_purple = '#af87ff'
export const color_brown = '#d19a66'
export const color_black = '#000'
export const color_white = '#fff'
