export const OPEN_DIALOG = "OPEN_DIALOG"
export const CLOSE_DIALOG = "CLOSE_DIALOG"

export const SWITCH_NODE_MODE = "SWITCH_NODE_MODE"

export const DIALOG_ID_NODE = "DIALOG_ID_NODE"

export const NODE_MODE_EDIT = "NODE_MODE_EDIT"
export const NODE_MODE_TEXT = "NODE_MODE_TEXT"

export const SET_SEARCH_STRING = "SET_SEARCH_STRING"
