import { switchMap, mergeMap, catchError, filter } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import {
  SAVE_NODE_START,
  DATA_TYPE_NODE,
  SAVE_NODE_NEW
} from '../constants/graph.constants.js'
import {
  LOAD_DATA_SUCCESS,
} from '../constants/data.constants.js'

import {
  saveNode,
  saveNodeToServer,
  saveNodeSuccess,
  saveNodeFail,
  updateNodeFrom,
  loadNode,
  saveNewNode,
} from '../actions/graph.actions.js'

import {
  selectNodeByIds,
  selectNodesAsArray
} from '../selectors/nodes.selectors.js'

import findAllInternalLinks from '../utils/findAllInternalLinks.js'

export function saveNodeNewEpic (action$, state$, {checkNodeExists, getNodeContent} ) {
  return action$.pipe(
    ofType(SAVE_NODE_NEW),
    mergeMap(async action => {
      const exists = await checkNodeExists(action.node.id)
      console.log("node exists: ", exists)
      if(exists){
        return loadNode(action.node.id)
      } else {
        return saveNode(action.node)
      }
    })
  )
}

export function saveNodeEpic (action$, state$ ) {
  return action$.pipe(
    ofType(SAVE_NODE_START),
    switchMap(action => {
      const node = action.node
      const newTo = findAllInternalLinks(action.node.content)
      const oldTo = [...node.to]
      node.to = newTo

      return [
        saveNodeSuccess(node),
      ].concat(
        selectNodeByIds(state$.value, {ids: oldTo.filter(nodeID => !newTo.includes(nodeID))})
        .map(n => updateNodeFrom(n.id, n.from.filter(n => n !== node.id)))
      ).concat(
        selectNodeByIds(state$.value, {ids: newTo.filter(nodeID => !oldTo.includes(nodeID))})
        .map(n => {
          return n.isNew ? saveNewNode({...n, from: [node.id]}) : updateNodeFrom(n.id, n.from.concat([node.id]))
        })
      ).concat(
        [saveNodeToServer(node)]
      )
    }),
    catchError((e, action) => {
      console.error(e)
      return [
        saveNodeFail(action.node, e)
      ]
    })
  )
}
export function updateLoadedNodeFromEpic (action$, state$) {
  return action$.pipe(
    ofType(LOAD_DATA_SUCCESS),
    filter(({datatype}) => datatype === DATA_TYPE_NODE),
    switchMap(action => {
      const node = action.data

      const newFrom = selectNodesAsArray(state$.value, { filter: 'TO', nodeID: node.id })


      return [updateNodeFrom(action.data.id, action.data.from.concat(newFrom.map(n => n.id)))]
        .concat(
          selectNodeByIds(state$.value, {ids: node.to})
          .map(n => {
            return n.isNew ? saveNewNode({...n, from: [node.id]}) : updateNodeFrom(n.id, n.from.concat([node.id]))
          })
        )
    })
  )
}

export default combineEpics(
  saveNodeEpic,
  saveNodeNewEpic,
  updateLoadedNodeFromEpic
)
