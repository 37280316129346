import {
  ADD_MESSAGE,
  HIDE_MESSAGE
} from '../constants/messages.constatns.js'

export const initialState = {
  byId: {},
  counter: 0
}

export default function messages (state = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        byId: {
          ...state.byId,
          [state.counter]: {
            messageType: action.messageType,
            text: action.text,
            timestamp: action.timestamp,
            id: state.counter
          }
        },
        counter: state.counter + 1
      }
    case HIDE_MESSAGE:
      return {
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            hide: true
          }
        }
      }
    default:
      return state
  }
}
