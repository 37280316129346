import React from 'react'
import Editor from 'react-simple-code-editor';
import Highlight from './highlight.view.js'

import '../styles/purify.highlightjs.css'

export default function CodeEditor ({ code, setCode }) {

  console.log("CodeEditor code:", code)

  return (
      <Editor
        value={code}
        onValueChange={c=> setCode(c)}
        highlight={c=> highlight(c)}
        padding={10}
        textareaClassName='code_editor__textarea'
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 14,
          background: 'rgba(0,0,0,0.2)',
          boxShadow: '0px 0px 16px 8px rgba(0,0,0,0.2)',
          width: '100%'
        }}
      />
    );
}

function highlight (code) {

  return (
    <Highlight language="markdown">{code}</Highlight>
  )
}
