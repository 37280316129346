import { createClient } from "webdav/web"

export function initClient(url, username, password){
  /*if(process.env && process.env.NODE_ENV === 'development'){
    console.log("DEV ENV initClient")
    window.webdavClient = createClient(
      url,
      {
        username,
        password,
        digest: true
      }
    )
  } else {*/
    window.webdavClient = createClient(
      url,
      {
        username,
        password,
      }
    )
  //}
  console.log("client initialized")
  console.log(`url: ${url},\n username: ${username},\n password: ${password}`)
  console.log('window.webdavClient', window.webdavClient)
}

export function clearClient(){
  window.webdavClient = undefined
}

//export function initClient(url = config.url, username = config.user, password = config.pw){

export async function getAllNodes(dir = "/mindmap", glob = "/**/*.md") {
  console.log('window.webdavClient', window.webdavClient)
  try{
    const data = await window.webdavClient.getDirectoryContents(
      dir,
      {
        glob,
      },
    )

    return data
      .map(s => s.basename.substring(0, s.basename.length - 3))
      .map((nodeID) => {
        return {
          id: nodeID,
          content: "",
          from: [],
          to: [],
          lastLoaded: 0
        }
      })
  } catch (e) {
    console.log("TEST ERROR", e)
    throw e
  }
}

export async function getNodeContent( nodeID, dir = "/mindmap" ) {
  console.log('window.webdavClient', window.webdavClient)
  console.log("getNodeContent", nodeID)
  const data = await window.webdavClient.getFileContents( 
    `${dir}/${nodeID}.md`,
    {
      format: "text",
    },
  )

  return {
    id: nodeID,
    content:data,
    from: [],
    to: []
  }
}

export async function checkFolderExists( dir = "/mindmap" ) {
  console.log("check exits: dir", dir)
  return await window.webdavClient.exists(
    dir
  )
}

export async function checkNodeExists( nodeID, dir = "/mindmap" ) {
  console.log("check exits: nodeID", nodeID)
  console.log("check exits: dir", dir)
  console.log("check exits: path", dir + nodeID + ".md" )
  return await window.webdavClient.exists(
    `${dir}/${nodeID}.md`
  )
}

export async function saveNodeContent( nodeID, content, dir = "/mindmap" ) {
  await window.webdavClient.putFileContents(
    `${dir}/${nodeID}.md`,
    content
  );
}
