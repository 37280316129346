import { switchMap, mergeMap, catchError, map } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import { push } from 'connected-react-router'

import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOAD_USER_START,
  LOAD_USER_SUCCESS,
  SAVE_USER_START,
  LOGOUT_USER,
} from '../constants/user.constants.js'

import {
  loginUser,
  loginUserSuccess,
  loginUserFail,
  loadUserSuccess,
  loadUserFail,
  saveUserSuccess,
  saveUserFail,
  logoutUser,
} from '../actions/user.actions.js'

import {
  loadGraph
} from '../actions/graph.actions.js'


export function loginUserEpic(action$, state$, { initClient, checkFolderExists }) {
  return action$.pipe(
    ofType(LOGIN_USER_START),
    mergeMap(async action => {
      initClient(action.davUrl, action.username, action.password)

      const mindmapexists = await checkFolderExists(`/${action.projectName}`)

      console.log("LOGIN USER, project-exsits:", mindmapexists)

      if(!mindmapexists){
        return loginUserFail(`Folder "/${action.projectName}" does not exist`)
      }

      return loginUserSuccess(action.username, action.davUrl, action.projectName, action.goBackTo)
    }),
    catchError((e) => [
      loginUserFail(e),
      logoutUser()
    ])
  )
}

export function loginUserSuccessEpic(action$, state$, { initClient }) {
  return action$.pipe(
    ofType(LOGIN_USER_SUCCESS),
    switchMap(action => {
      return [
        loadGraph(),
        push(action.goBackTo),
      ]
    })
  )
}
export function logoutUserEpic(action$, state$, { clearClient, storeUserData}) {
  return action$.pipe(
    ofType(LOGOUT_USER),
    switchMap(action => {
      clearClient()
      storeUserData(null)
      return [push('/')]
    })
  )
}

export function loadUserEpic(action$, state$, { getUserData }){
  return action$.pipe(
    ofType(LOAD_USER_START),
    mergeMap(async action => {
      const user = await getUserData()

      return loadUserSuccess(user);
    }),
    catchError((e) => [
      loadUserFail(e),
      logoutUser()
    ])
  )
}
export function loadUserSuccessEpic(action$, state$){
  return action$.pipe(
    ofType(LOAD_USER_SUCCESS),
    map(action => loginUser(action.user.name, action.user.password, action.user.davUrl, action.user.projectName))
  )
}
export function saveUserEpic(action$, state$, { storeUserData }){
  return action$.pipe(
    ofType(SAVE_USER_START),
    mergeMap(async action => {
      await storeUserData(action.user)

      return saveUserSuccess(action.user);
    }),
    catchError((e) => saveUserFail(e))
  )
}

export default combineEpics(
  loginUserEpic,
  loginUserSuccessEpic,
  logoutUserEpic,
  saveUserEpic,
  loadUserEpic,
  loadUserSuccessEpic
)
