import { createSelector } from 'reselect'

import { selectCurrentNode, selectNodes } from './nodes.selectors.js'

import { selectIsLoading } from './data.selectors.js'

import idToName from '../utils/idToName.js'

export function selectLastLoaded(state) {
  return state.graph.lastLoaded
} 

export const selectFromNodeIds = createSelector(
  [selectCurrentNode, selectNodes],
  (currentNode, nodes) => {
    const from = new Set()
    for(let key in nodes){
      const n =nodes[key]
      if(n.to.includes(currentNode.id)){
        from.add(n.id)
      }
    }
    return Array.from(from)
  }
)

export const getGraphByRoot = createSelector(
  [selectIsLoading, selectNodes, selectCurrentNode],
  (loading, graph, currentNode) => {
    const elements = new Set()


    function addNode(node, maxDepth){

      if(maxDepth < 0) {
        return 
      }
      const nodeOptions = {}
      if(node.id === currentNode.id) {
        console.log("I am currentNode", node.id)
        nodeOptions['nodeClasses'] = 'current_node'
      }
      const { nodes, edges } = transformNodeToData(node, nodeOptions)
      elements.add(...nodes)
      if(maxDepth > 0){
        edges.forEach(e => elements.add(e))
      }

      node.to.forEach((id) => {

        if(graph[id]){
          addNode(graph[id], maxDepth - 1)
        } else {
          addNode({id, to: [], from:[]}, maxDepth - 1)
        }
      })
      node.from.forEach((id) => {
        if(graph[id]){
          addNode(graph[id], maxDepth - 1)
        } else {
          addNode({id, to: [], from:[]}, maxDepth - 1)
        }   
      })
    }
    if(!loading){
      addNode(currentNode, 2)
    }
    return Array.from(elements)
  }
)

function appendNodeClasses(nodeClasses, nodeID){
  if(nodeID.endsWith('.cat')){
    nodeClasses += " node_type_category"
  }
  if(nodeID.endsWith('.idea')){
    nodeClasses += " node_type_idea"
  }
  return nodeClasses
}

export function transformNodeToData(node, { nodeClasses = '' }) {
  if(node === undefined){
    return {
      nodes: [],
      edges: [],
    }
  }
  
  nodeClasses = appendNodeClasses(nodeClasses, node.id)

  return {
    nodes: [
      {
        data: {
          id: node.id,
          label: idToName(node.id),
        },
        classes: nodeClasses
      },
    ],
    edges: []
      .concat(node.to.map(target => {
        return {
          data: {
            id: node.id+target,
            source: node.id,
            target
          }
        }
      }))
      .concat(node.from.map(source => {
        return {
          data: {
            id: source + node.id,
            target: node.id,
            source
          }
        }
      }))
  }
}
