import { connect } from 'react-redux'

import View from '../components/node.dialog.js'

import {
  DIALOG_ID_NODE
} from '../constants/ui.constants.js'

import { 
  selectIsLoading,
} from '../selectors/data.selectors.js'

import { 
  selectDialogOpen,
  selectNodeMode
} from '../selectors/ui.selectors.js'

import {
  openDialog,
  closeDialog,
  switchNodeMode
} from '../actions/ui.actions.js'

const mapStateToProps = (state, props) => {
  return {
    isLoading: selectIsLoading(state, props),
    dialogOpen: selectDialogOpen(state, {...props, dialogID: DIALOG_ID_NODE}),
    nodeMode: selectNodeMode(state),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openDialog: () => dispatch(openDialog(DIALOG_ID_NODE)),
    closeDialog: () => dispatch(closeDialog(DIALOG_ID_NODE)),
    switchNodeMode: (mode) => dispatch(switchNodeMode(mode))
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
