import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'
import idToName from '../utils/idToName.js'

import '../styles/graphIndex.less'

export default function GraphIndex({ nodesList = [], loading, lastLoaded, loadGraph }) {
  useEffect(() => {
    if(!loading && Date.now() - lastLoaded >= 1800000 ) {
      //     loadGraph()
    }
  }, [nodesList, lastLoaded, loading, loadGraph]);
  nodesList = nodesList.map((node) => {
    return <NodeListLink key={node.id} node={node}/>
  })
  return (
    <ul className="graph_index">
      {nodesList}
    </ul>
  );
}

function NodeListLink({node}){

  const buttonClasses = [
    'button'
  ]
  if(node.id.endsWith('.cat')){
    buttonClasses.unshift('button--red')
  } else if(node.id.endsWith('.idea')){
    buttonClasses.unshift('button--yellow')
  } else {
    buttonClasses.unshift('button--blue')
  }
  return (
    <li className="graph_index__link">
      <Link className={buttonClasses.join(' ')} to={`/graph/${node.id}`}>{idToName(node.id)}</Link>
    </li>
  )
}
