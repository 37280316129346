import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";

import CodeEditor from './codeEditor.view.js'

import '../styles/editform.styles.less'

export default function EditForm({ node = {content:"", to:[]}, saveNode }) {

  const [ code, setCode ] = useState(node.content);

  useEffect(() => {
    setCode(node.content)
  }, [node])

  const { handleSubmit } = useForm();
  const onSubmit = data => {
    saveNode({
      ...node,
      content: code,
    })
  };

  return (
    <form className="edit_form box__content" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit_form__section">
        <CodeEditor code={code} setCode={setCode} />
      </div>
      <div className="edit_form__section">
        <input className="button" type="submit" value="Save"/>
      </div>
    </form>
  );
}

