import Cytoscape from 'cytoscape'
import cise from 'cytoscape-cise';

import React, {useRef, useEffect, useCallback} from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import cytoscape_stylesheet from '../utils/cytoscape_style.js'

Cytoscape.use( cise );

const layout = {
  name: 'cise',
}

export default function CytoscapeView({ elements, onNodeClicked}) {
  const cy = useRef(null)

  const setCytoscape = useCallback(
    (ref) => {
      cy.current = ref
      cy.current.layout(layout).run()
    },
    [cy]
  )
  useCyEvent('tap', 'node', (e) => {
    const node = e.target
    onNodeClicked(node.id())
  }, cy)
  return (
    <CytoscapeComponent 
      elements={elements} 
      style={ { width: '100vw', height: '100vh' } } 
      stylesheet={cytoscape_stylesheet}
      layout={ layout } 
      cy={setCytoscape}
    />
  )
}

function useCyEvent(event, type, handler, cyRef){
  useEffect(() => {
    if(cyRef.current){
      const cy = cyRef.current

      cy.on(event, type, handler)

      return function cleanup() {
        cy.removeListener(event, handler)
      }
    }
  })
}
