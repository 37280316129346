import {
  SAVE_SEARCH_INDEX,
  LOAD_DATA_START,
  LOAD_DATA_FAIL,
  LOAD_DATA_SUCCESS
} from '../constants/data.constants.js'


export const initialState = {
  index: undefined,
  loading: 0,
}

export default function data (state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_START:
      return { ...state, loading: state.loading + 1 }
    case LOAD_DATA_FAIL:
      return { ...state, loading: state.loading - 1 }
    case LOAD_DATA_SUCCESS:
      return { ...state, loading: state.loading - 1 }
    case SAVE_SEARCH_INDEX:
      return {
        ...state,
        index: action.index
      }
    default:
      return state
  }
}
