import React from 'react'

import { Route, Redirect } from 'react-router-dom'

export default function ProtectedRoute ({ render, loggedIn, ...rest }) {
  console.log("ProtectedRoute, loggedIn:", loggedIn)
  return (
    <Route
      {...rest}
      render={
        routerProps => {
          console.log('test, loggedIn', loggedIn)
          console.log('test, ', routerProps)
          return (
          loggedIn
            ? render(routerProps)
            : <Redirect to={{
                pathname: '/login',
                from: routerProps.match.url !== '/login' ? routerProps.match.url : '/',
              }}/>
          
          )
        }
      }
    />
  )
}
