import {
  NEXTCLOUD_CONNECT_START,
  NEXTCLOUD_CONNECT_POLL,
  NEXTCLOUD_CONNECT_SUCCESS,
  NEXTCLOUD_CONNECT_FAIL
} from '../constants/nextcloud.constants.js'

/**
 * @param {string} nextCloudUrl
 */
export function connectWithNextCloud(nextCloudUrl, userConfig){

  return {
    type: NEXTCLOUD_CONNECT_START,
    nextCloudUrl,
    userConfig
  }
}

/**
 * @param {string} endpoint
 * @param {string} token
 * @param {number} count
 */
export function connectWithNextCloudPoll(endpoint, token, count, userConfig){
  return {
    type: NEXTCLOUD_CONNECT_POLL,
    endpoint,
    token,
    count,
    userConfig
  }
}

/**
 * @param {string} serverUrl
 * @param {string} loginName,
 * @param {string} appPassword,
 */
export function connectWithNextCloudSuccess(serverUrl, loginName, appPassword, userConfig){
  return {
    type: NEXTCLOUD_CONNECT_SUCCESS,
    serverUrl,
    loginName,
    appPassword,
    userConfig
  }
}

/**
 * @param {Object} error
 */
export function connectWithNextCloudFail(error){
  return {
    type: NEXTCLOUD_CONNECT_FAIL,
    error
  }
}
