import { connect } from 'react-redux'

import View from '../components/user.nextcloud.js'

import {
  connectWithNextCloud
} from '../actions/nextcloud.actions.js'

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    connectWithNextCloud: (nextCloudUrl, projectName) => {
     dispatch(connectWithNextCloud(nextCloudUrl, projectName))
    }
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
