import React from 'react';
import TextView from './text.view.js';
import EditForm from '../containers/node.edit.container.js'

import { NODE_MODE_EDIT, NODE_MODE_TEXT } from '../constants/ui.constants.js'

export default function NodeView({
  node, 
  isLoading,
  dialogOpen, 
  nodeMode,
  openDialog, 
  closeDialog, 
  switchNodeMode,
}) {
  const textViewClasses = [
    'node_view__dialog',
    dialogOpen ? 'node_view__dialog--open' :'node_view__dialog--closed'
  ] 

  let main = ''
  const toolbar = []

  if(nodeMode === NODE_MODE_EDIT){
    main = (<EditForm node={node} />)
    toolbar.push(
      <button key="button--text" onClick={() => switchNodeMode(NODE_MODE_TEXT)} className="button button--small_icon">
        <i className="icon icon--text">Text</i>
      </button>
    )
    
  } else if (nodeMode === NODE_MODE_TEXT) {
    main = (<TextView id={node.id} onClose={closeDialog} content={node.content} />)
    toolbar.push(
      <button key="button--edit" onClick={() => switchNodeMode(NODE_MODE_EDIT)} className="button button--small_icon">
        <i className="icon icon--edit">Edit</i>
      </button>
    )
  }

  if(isLoading){
    toolbar.unshift(
      (
        <button key="indicator--loading" className="button button--small_icon">
        <i className="icon icon--loading">Loading</i>
      </button>
      )
    )
  }

  toolbar.push(
    <button key="button--exit" onClick={closeDialog} className="button button--small_icon">
      <i className="icon icon--exit">Exit</i>
    </button>
  )

  return (
    <div className={textViewClasses.join(' ')} >
      <div className="box"> 
        <div className="box__toolbar">
          {toolbar}
        </div>
        {main}
      </div>
    </div>
  )
}
