export const DATA_TYPE_GRAPH = "GRAPH"
export const DATA_TYPE_NODE= "NODE"

export const SAVE_NODE_START = "SAVE_NODE_START"
export const SAVE_NODE_NEW = "SAVE_NODE_NEW"
export const SAVE_NODE_SUCCESS = "SAVE_NODE_SUCCESS"
export const SAVE_NODE_FAIL = "SAVE_NODE_FAIL"

export const UPDATE_NODE_FROM = "UPDATE_NODE_FROM"
export const UPDATE_NODE_TO = "UPDATE_NODE_TO"

/**
 * A node is one markdown file on the server
 *
 * @typedef {Object} Node
 * @property {string} id - node id, name of the file computed from name with whitespaces replaced
 * @property {string} name - name of the node 
 * @property {string} content - file content as string
 * @property {string[]} to - links to other files inside the content like: [[ WikiLinks ]]
 * @property {string[]} from - Links to this file inside other loaded files.
 * @property {number} lastLoaded - timestamp
 */


