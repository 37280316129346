import { connect } from 'react-redux'

import View from '../components/graph.index.js'

import {
  selectIsLoading
} from '../selectors/data.selectors.js'

import { 
  selectNodesAsArray
} from '../selectors/nodes.selectors.js'
import { 
  selectLastLoaded,
} from '../selectors/graph.selectors.js'

import {
  loadGraph
} from '../actions/graph.actions.js'

const mapStateToProps = (state, props) => {
  return {
    nodesList: selectNodesAsArray(state),
    lastLoaded: selectLastLoaded(state),
    loading: selectIsLoading(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadGraph: (nodeID) => dispatch(loadGraph(nodeID))
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container
