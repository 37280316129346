import { connect } from 'react-redux'

import View from '../components/node.view.js'
import Button from '../components/currentNode.button.js'

import {
  DIALOG_ID_NODE
} from '../constants/ui.constants.js'

import {
  selectIsLoading
} from '../selectors/data.selectors.js'

import { 
  getGraphByRoot,
} from '../selectors/graph.selectors.js'

import { 
  selectCurrentNode,
  selectNodes
} from '../selectors/nodes.selectors.js'

import { 
  selectDialogOpen
} from '../selectors/ui.selectors.js'

import {
  loadNode
} from '../actions/graph.actions.js'

import {
  openDialog,
  closeDialog,
} from '../actions/ui.actions.js'

import { push } from 'connected-react-router'

const mapStateToProps = (state, props) => {
  return {
    elements: getGraphByRoot(state, props),
    loading: selectIsLoading(state),
    currentNode: selectCurrentNode(state, props),
    nodes: selectNodes(state),
    dialogOpen: selectDialogOpen(state, {...props, dialogID: DIALOG_ID_NODE}),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeCurrentNode: (id) => dispatch(push(`/graph/${id}`)),
    openDialog: () => dispatch(openDialog(DIALOG_ID_NODE)),
    closeDialog: () => dispatch(closeDialog(DIALOG_ID_NODE)),
    loadNode: (nodeID) => dispatch(loadNode(nodeID))
  }
}

const Container= connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container

export const CurrentNodeButton = connect(
  (state, props) => {
    return {
      node: selectCurrentNode(state, props),
      dialogOpen: selectDialogOpen(state, {...props, dialogID: DIALOG_ID_NODE}),
    }
  },
  dispatch => {
    return {
      openDialog: () => dispatch(openDialog(DIALOG_ID_NODE)),
      closeDialog: () => dispatch(closeDialog(DIALOG_ID_NODE)),
    }
  }

)(Button)
