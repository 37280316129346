import transformNode from './transformNode.js'

export default function transformNodeArrayToObject(nodeArray) {
  const res =  {}
  for (let node of nodeArray){
    res[node.id] = transformNode(node)

  }

  return res
}
