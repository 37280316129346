import { connect } from 'react-redux'

import View from '../components/node.edit.js'

import { 
  selectIsLoading,
} from '../selectors/data.selectors.js'

import {
  saveNode,
} from '../actions/graph.actions.js'

const mapStateToProps = (state, props) => {
  return {
    loading: selectIsLoading(state),
    ...props
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveNode: (node) => dispatch(saveNode(node)),
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default Container


