export const LOGIN_USER_START = "LOGIN_USER_START"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL"
export const LOGOUT_USER = "LOGOUT_USER"

export const SAVE_USER_START = "SAVE_USER_START"
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS"
export const SAVE_USER_FAIL = "SAVE_USER_FAIL"

export const LOAD_USER_START = "LOAD_USER_START"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"


export const USER_ANNONYMOUS = "USER_ANNONYMOUS"

