import {
  LOAD_DATA_SUCCESS,
  LOAD_DATA_FAIL,
  LOAD_DATA_START
} from '../constants/data.constants.js'

export default function generateDataHelper(state, action, dataHandlers){
  switch (action.type) {
    case LOAD_DATA_START: 
      return {
        ...state,
      }
    case LOAD_DATA_SUCCESS: 
      return dataHandlers[action.datatype]({...state }, action)
    case LOAD_DATA_FAIL: 
      return {
        ...state,
      }
    default:
      return state
  }     
}
