import React from 'react';
import ReactMarkdown from 'react-markdown'
import wikiLinkPlugin from 'remark-wiki-link'
import { Link } from 'react-router-dom'

import nameToId from '../utils/nameToId.js'

import '../styles/text.less'

function TextView( { id, content } ) {
  const remarkPlugins = [
    [
      wikiLinkPlugin,
      {
        pageResolver: (s) => {
          return [s]
        },
        permalinks: [
          'testlink-exits'
        ],
        hrefTemplate: (s) => `/graph/${nameToId(s)}`,
        wikiLinkClassName: 'link_internal',
        newClassName: 'link_internal--new'
      }
    ]
  ]

  return (
    <div className="text_view box__content">
      <ReactMarkdown
        source={content}
        plugins={remarkPlugins}
        renderers={{wikiLink:InternalLink}}
      />
    </div>
  );
}

function InternalLink(props){
  const { alias, hProperties } = props.data
  const { className, href } = hProperties
  return (<Link className={className} to={href}>{alias}</Link>)
}

export default TextView;
