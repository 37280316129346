import {
  color_green,
  color_black,
  color_orange,
  color_yellow,
  color_red,
  color_blue
} from './colors.js'

const style = [
  {
    selector: 'node',
    style: {
      'background-color': color_blue,
      shape: 'round-rectangle',
      width: 'label',
      height: 'label',
      padding: '10px'

    }   
  },
  {
    selector: '.current_node',
    style: {
      'background-color': color_green,
    }
  },
  {
    selector: '.node_type_category',
    style: {
      'background-color': color_red,
    }
  },
  {
    selector: '.node_type_idea',
    style: {
      'background-color': color_yellow,
    }
  },
  {
    selector: 'node[label]',
    style: {
      'color': color_black,
      "label": "data(label)",
      "text-valign": "center",
      "text-halign": "center"
    }
  },
  {
    selector: 'edge',
    style: {
        'width': 3,
        'line-color': color_orange,
        'target-arrow-color': color_orange,
        'target-arrow-shape': 'triangle',
        'curve-style': 'bezier'
    }
  }
]

export default style
