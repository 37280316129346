import React, {useEffect} from 'react';
import NodeDialog from '../containers/node.dialog.container.js'

import CytoscapeView from './cytoscape.view.js'

import '../styles/nodeView.less'

export default function NodeView({ elements, changeCurrentNode, currentNode, loading, nodes, loadNode, openDialog}) {
  useEffect(() => {
    if((!currentNode.lastLoaded) ||  Date.now() - currentNode.lastLoaded >= 1800000 ) 
    {
      loadNode(currentNode.id)
    }
  }, [currentNode, loadNode])

  function nodeClicked(id) {
    if(id === currentNode.id) {
      openDialog()
    } else {
      changeCurrentNode(id)
    }   
  }

  return (
    <div className="node_view">
      <div className="node_view__graph">
        { elements.length > 0 ? <CytoscapeView elements={elements} onNodeClicked={nodeClicked} /> : '' }
      </div>
      <NodeDialog node={currentNode} />
    </div>
  )
}
