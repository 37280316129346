export const LOAD_DATA_START = 'LOAD_DATA_START'
export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAIL = 'LOAD_DATA_FAIL'

export const UPDATE_SEARCH_INDEX = 'UPDATE_SEARCH_INDEX'
export const SAVE_SEARCH_INDEX = 'SAVE_SEARCH_INDEX'

export const SAVE_DATA_START = 'SAVE_DATA_START'
export const SAVE_DATA_SUCCESS = 'SAVE_DATA_SUCCESS'
export const SAVE_DATA_FAIL = 'SAVE_DATA_FAIL'

export const FUSE_OPTIONS = {
  includeScore: true,
  keys: ['id']
}
