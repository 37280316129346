import { map, switchMap, delay, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import {
  LOAD_DATA_FAIL,
  SAVE_DATA_FAIL,
  SAVE_DATA_SUCCESS,
} from '../constants/data.constants.js'

import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
} from '../constants/user.constants.js'

import {
  ADD_MESSAGE,
  MESSAGE_TYPE_ERROR,
  //MESSAGE_TYPE_LOG,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TIME_IN_SECONDS
} from '../constants/messages.constatns.js'

import { addMessage, hideMessage } from '../actions/messages.actions.js'

const messagesSpecs = {
  [SAVE_DATA_FAIL]: {
    type: MESSAGE_TYPE_ERROR,
    createMessage: (action) => `Error: Data could not be saved \n ${action.error}`
  },
  [LOAD_DATA_FAIL]: {
    type: MESSAGE_TYPE_ERROR,
    createMessage: (action) => `Error: Data ${action.datatype || ''} could not be loaded \n ${action.error}`
  },
  [LOGIN_USER_FAIL]: {
    type: MESSAGE_TYPE_ERROR,
    createMessage: (action) => `Error: Login Failed \n ${action.error}`
  },
  [LOGIN_USER_SUCCESS]: {
    type: MESSAGE_TYPE_SUCCESS,
    createMessage: (action) => `Login successfull. \n Welcome ${action.username}`
  },
  [SAVE_DATA_SUCCESS]: {
    type: MESSAGE_TYPE_SUCCESS,
    createMessage: (action) => `Save Node successfull.`
  },
}

const messagesTypes = Object.keys(messagesSpecs)

export function messagesEpic (action$, state$) {
  return action$.pipe(
    filter(({type}) => messagesTypes.includes(type)),
    map((action) => {
      const messageSpec = messagesSpecs[action.type]
      return addMessage(messageSpec.type, messageSpec.createMessage(action))
    })
  )
}

export function hideMessageEpic (action$) {
  return action$.pipe(
    ofType(ADD_MESSAGE),
    switchMap(action => {
      return of(hideMessage(action.id)).pipe(
        delay(MESSAGE_TIME_IN_SECONDS * 1000)
      )
    })
  )
}

export default combineEpics(hideMessageEpic, messagesEpic)
