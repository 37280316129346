import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import idToName from '../utils/idToName.js'
import nameToID from '../utils/nameToId.js'

import '../styles/searchBar.less'

export default function SearchBar ( { searchString, searchResults, updateSearch } ) {

  const [ focus, setFocus] = useState(false);


  function onInputChanged(e){
    updateSearch(e.target.value);
  }

  function onSetValue(v){
    updateSearch(v);
  }

  const resultClasses = [
    'box',
    'search_bar__results',
    (focus && searchResults.length > 0 ? 'search_bar__results--show' : 'search_bar__results--hidden'),
    (searchResults.length > 0 ? '' : 'search_bar__results--noContent'),
  ]


  return (
    <div className="search_bar">
      <input 
        name="search_bar" 
        className="field--search_bar field " 
        placeholder="Search..." 
        value={searchString} 
        onChange={onInputChanged} 
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <div className={resultClasses.join(' ')}>
        {searchResults.map(r => (
            <SearchResult 
          key={r.item.id} 
          nodeID={r.item.id} 
          setValue={onSetValue}
        />)
        )}
      </div>
      <Link 
        className="button button--small_icon"
        to={`/graph/${nameToID(searchString)}`}
      >
        <i className="icon icon--goto">GoTo</i>
      </Link>
    </div>
  )
}

function SearchResult({nodeID, setValue}) {
  const buttonClasses = [
    'button'
  ]
  if(nodeID.endsWith('.cat')){
    buttonClasses.unshift('button--red')
  } else if(nodeID.endsWith('.idea')){
    buttonClasses.unshift('button--yellow')
  } else {
    buttonClasses.unshift('button--blue')
  }

  return (
    <div className="result_item">
      <button className={buttonClasses.join(' ')} onClick={() => setValue(idToName(nodeID))}>{idToName(nodeID)}</button>
    </div>
  )
}
