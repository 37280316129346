import nameToId from './nameToId.js'

/**
 * @param {string} source - markdown sourcecode 
 * @return {string[]} - ids of nodes
 */
export default function findAllInternalLinks (source) {
  const linkRe = /\[\[[A-Za-z0-9-\s.]+\]\]/g
  const matches = [...source.matchAll(linkRe)]

  const res = Array.from(
    new Set(
      matches.map(
        m => nameToId(m[0].slice(2,m[0].length-2))
      )
    )
  )


  return res
}
