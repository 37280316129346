/**
 * start login via v2 login flow
 * @param {string} serverUrl - nextcloud server url
 * @return {Object} pollData
 */
export async function nextCloudLogin(serverUrl){
  const loginUrl = `${serverUrl}${serverUrl.endsWith('/') ? '' : '/'}index.php/login/v2`

  const res = await fetch(loginUrl, { method: 'POST' })

  if(res.status !== 200){
    throw new Error(`HTTPError during nextcloud v2 login, status: ${res.status}`)
  }

  return res.json()
}

/**
 * start login via v2 login flow
 * @param {string} endpoint - endpoint for polling
 * @param {string} token - token for polling
 * @return {Object} authData
 */
export async function pollNextCloud(endpoint, token){
  const body = new FormData();

  body.append('token', token);

  const res = await fetch(endpoint, {
    method: 'POST',
    body
  })

  if(res.status === 404){
    return false
  }

  if(res.status !== 200){
    throw new Error(`HTTPError during nextcloud v2 login, status: ${res.status}`)
  }

  return res.json()
}
