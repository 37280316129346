import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  SAVE_USER_SUCCESS,
  LOAD_USER_SUCCESS,
  USER_ANNONYMOUS,
} from '../constants/user.constants.js'

export const initialState = {
  user: USER_ANNONYMOUS,
  davUrl: null
}

export default function graph (state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        ...{
          user: action.user.name,
          davUrl: action.user.davUrl,
          nextcloud: action.user.nextcloud,
          projectName: action.user.projectName
        }
      }
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        ...{
          user: action.user.name,
          davUrl: action.user.davUrl,
          nextcloud: action.user.nextcloud,
          projectName: action.user.projectName
        }
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...{
          user: action.username,
          davUrl: action.davUrl ? action.davUrl : state.davUrl
        }
      }
    case LOGOUT_USER:
      return {
        user: USER_ANNONYMOUS,
      }
    default:
      return state
  }
}
