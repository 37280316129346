import {
  DATA_TYPE_GRAPH,
  DATA_TYPE_NODE,
  SAVE_NODE_SUCCESS,
  UPDATE_NODE_TO,
  UPDATE_NODE_FROM
} from '../constants/graph.constants.js'

import dataHelper from './data.helper.js'

export const initialState = {
  nodesById: {},
  lastLoaded: 0
}

export default function graph (state = initialState, action) {
  switch (action.type) {
    case UPDATE_NODE_FROM:
      return {
        ...state,
        nodesById: {
          ...state.nodesById,
          [action.nodeID]: {
            ...state.nodesById[action.nodeID],
            from: action.from
          }
        }
      }
    case UPDATE_NODE_TO:
      return {
        ...state,
        nodesById: {
          ...state.nodesById,
          [action.nodeID]: {
            ...state.nodesById[action.nodeID],
            to: action.to
          }
        }
      }
    case SAVE_NODE_SUCCESS:
      return {
        ...state,
        nodesById: {
          ...state.nodesById,
          [action.node.id]: {
            ...action.node
          }
        }
      }
    default:
      return dataHelper(state, action, {
        [DATA_TYPE_GRAPH]: addGraphIndexHandler,
        [DATA_TYPE_NODE]: addNodeHandler
      })
  }
}

function addGraphIndexHandler(state, action) {
  return {
    ...state,
    lastLoaded: action.timestamp,
    nodesById: {
      ...action.data.nodes,
      ...state.nodesById
    },
  }
}
function addNodeHandler(state, action) {
  return {
    ...state,
    nodesById: {
      ...state.nodesById,
      [action.data.id]: {
        ...action.data,
        lastLoaded: action.timestamp
      }
    },
  }
}
