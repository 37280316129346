import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SWITCH_NODE_MODE,
  SET_SEARCH_STRING
} from '../constants/ui.constants.js'

/**
 * @param {string} dialog - dialog id
 */
export function openDialog(dialog) {
  return {
    type: OPEN_DIALOG,
    dialog
  }
}
/**
 * @param {string} dialog - dialog id
 */
export function closeDialog(dialog) {
  return {
    type: CLOSE_DIALOG,
    dialog
  }
}
/**
 * @param {string} mode - node mode: NODE_MODE_EDIT or NODE_MODE_TEXT
 */
export function switchNodeMode(mode) {
  return {
    type: SWITCH_NODE_MODE,
    mode
  }
}

/**
 * @param {string} search
 */
export function setSearchString(search) {
  return {
    type: SET_SEARCH_STRING,
    search
  }
}
